<template>
  <f7-page class="login-page">
    <!-- <f7-navbar back-link="Back"> </f7-navbar> -->

    <meta name="robots" content="noindex" />

    <section class="register-container">
      <div class="container">
        <img src="@/assets/images/svg/mobileauth.svg" :alt="$t.getTranslation('LBL_MOBILE_AUTHENTICATION')" loading="lazy" />

        <div class="info">
          <h2>{{ $t.getTranslation("LBL_STEP_1_OF_3") }}</h2>
          <h1>{{ $t.getTranslation("LBL_MOBILE_AUTHENTICATION") }}</h1>

          <p>{{ $t.getTranslation("LBL_MOBILE_AUTHENTICATION_SUB") }}</p>
        </div>
        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.MobileCode"
            name="MobileCode"
            :required="validationRules?.MobileCode?.required"
            :minlength="validationRules?.MobileCode?.minimumLength"
            :maxlength="validationRules?.MobileCode?.maximumLength"
            :label="$t.getTranslation('LBL_MOBILE_CODE')"
            :placeholder="$t.getTranslation('LBL_MOBILE_CODE_PLACEHOLDER')"
            :info="$t.getTranslation('LBL_MOBILE_CODE_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="mobileCodeList && mobileCodeList.length > 0">
              <option v-for="mobileCode in mobileCodeList" :key="'mc_' + mobileCode.value" :value="mobileCode.value">{{ mobileCode.name }}</option>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.MobileNumber"
            name="MobileNumber"
            :required="validationRules?.MobileNumber?.required"
            :minlength="validationRules?.MobileNumber?.minimumLength"
            :maxlength="validationRules?.MobileNumber?.maximumLength"
            :label="$t.getTranslation('LBL_MOBILE_NUMBER')"
            :placeholder="$t.getTranslation('LBL_MOBILE_NUMBER_PLACEHOLDER')"
            :info="$t.getTranslation('LBL_MOBILE_NUMBER_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="tel"
            validate
            clear-button
            acceptinput="mobilenumber"
          >
          </f7-list-input>
        </f7-list>

        <f7-button fill large raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableNextButton" @click="next">
          {{ buttonText }}
        </f7-button>

        <f7-button v-if="isAlreadyHaveCode" large @click="alreadyHaveCode">{{ $t.getTranslation("LBL_ALREADY_HAVE_CODE") }}</f7-button>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted, inject } from "vue";
import { Dom7 } from "framework7";

import { $HTTP } from "@/utils/axios";
import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

export default defineComponent({
  name: "RegisterMobileAuthenticationPage",
  components: {},
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();

    const isButtonProcessing = ref(false);
    const countdown = ref(0);

    store.dispatch("config/fetchData", { params: { mobileCodeList: 1, mobileCodeDefault: 1 } });
    const mobileCodeList = computed(() => store.getters["config/getData"]?.mobileCodeList);
    const defaultMobileCode = computed(() => store.getters["config/getData"]?.mobileCodeDefault);

    const formData = reactive({
      MobileCode: defaultMobileCode.value,
      MobileNumber: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(() => {
      let registerInfo = store.getters["register/getData"];
      for (let keys in formData) {
        formData[keys] = registerInfo[keys];
      }

      Dom7("li[acceptinput='mobilenumber'] input").on("keypress", (e) => {
        let regex = new RegExp("^[0-9]+$");
        let character = String.fromCharCode(!e.charCode ? e.which : e.charCode);

        if (!regex.test(character)) {
          e.preventDefault();
        }
      });
    });

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const checkAllowNextOTPRequest = () => {
      let registerInfo = store.getters["register/getData"];
      let newUnixTime = new Date().getTime() / 1000;

      if (registerInfo && (formData.MobileCode == "" || registerInfo.MobileCode == formData.MobileCode) && (formData.MobileNumber == "" || registerInfo.MobileNumber == formData.MobileNumber)) {
        if (newUnixTime <= registerInfo.OTPNextRequest) {
          let countDownInterval = setInterval(() => {
            let newUnixTime = new Date().getTime() / 1000;
            let distance = (registerInfo.OTPNextRequest - newUnixTime) * 1000;

            if (distance > 0) {
              let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
              let seconds = Math.floor((distance % (1000 * 60)) / 1000);

              countdown.value = `${minutes}:${helpers.padStart(seconds, 2, "0")}`;
            } else {
              countdown.value = 0;
              clearInterval(countDownInterval);
            }
          }, 1000);
        } else {
          countdown.value = 0;
          return true;
        }
      } else {
        countdown.value = 0;
        return true;
      }
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);

      checkAllowNextOTPRequest();

      return !isValid || countdown.value != 0;
    });

    const buttonText = computed(() => {
      return countdown.value != 0 ? `${$t.getTranslation("LBL_VERIFY")} - ${countdown.value}` : $t.getTranslation("LBL_VERIFY");
    });

    const isAlreadyHaveCode = computed(() => {
      return countdown.value != 0 ? true : false;
    });

    const next = async () => {
      try {
        let isValid = validate(true);

        if (isValid) {
          let registerInfo = store.getters["register/getData"];

          isButtonProcessing.value = true;
          helpers.showLoader();

          // CHECK MobileNumber as unique
          let res = await $HTTP.post("/mobile/auth/check/mobile", formData);

          if (res && res.status === 200 && res?.data?.data) {
            isButtonProcessing.value = false;
            helpers.hideLoader();

            let returnCheckData = res.data.data;

            if (returnCheckData && returnCheckData.isRegistered && registerInfo.OpenId == "") {
              return helpers.createNotification({
                type: "info",
                title: $t.getTranslation("LBL_INFO"),
                message: $t.getTranslation("LBL_INFO_MOBILE_IN_USED"),
              });
            } else if (returnCheckData && (returnCheckData.isRegistered === false || (returnCheckData.isRegistered && registerInfo.OpenId != ""))) {
              if (returnCheckData.isConnected) {
                return helpers.createNotification({
                  type: "info",
                  title: $t.getTranslation("LBL_INFO"),
                  message: $t.getTranslation("LBL_INFO_MOBILE_IN_USED"),
                });
              }

              let res = await $HTTP.post("/mobile/register/otp/generate", formData);

              if (res && res.status === 200 && res.data && res.data.data) {
                let returnOTPData = res.data.data;
                if (returnOTPData && returnOTPData.isOTPGenerated) {
                  store.dispatch("register/setData", {
                    ...formData,
                    IsOTPGenerated: 1,
                    OTPNextRequest: returnOTPData.OTPNextRequest,
                    IsMergeAccount: returnCheckData.isRegistered && registerInfo.OpenId != "" ? 1 : 0,
                  });

                  if (returnCheckData.isRegistered && registerInfo.OpenId != "") {
                    props.f7router.navigate("/register/mobilemerge/");
                  } else {
                    props.f7router.navigate("/register/mobileverification/");
                  }
                  return;
                }
              }
            }
          }

          throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
        }
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();

        helpers.catchError(err, true);
      }
    };

    const alreadyHaveCode = () => {
      props.f7router.navigate("/register/mobileverification/");
    };

    return {
      mobileCodeList,
      formData,
      validationRules,
      buttonText,
      isButtonProcessing,
      isDisableNextButton,
      next,
      isAlreadyHaveCode,
      alreadyHaveCode,
    };
  },
});
</script>
